/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import Img from 'gatsby-image/withIEPolyfill'

import { Row, Cell } from '../Grid'
import { RouterLink, buildStandaloneLinkStyle } from './Link'
import { typeRamp, media } from '../../constants'
import Container from '../Container'

const propTypes = {
	to: PropTypes.string,
	fluidImageData: PropTypes.object,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
}

const defaultProps = {
	to: null,
	fluidImageData: {},
}

const WorkBannerLink = ({ to, title, description, fluidImageData }) => {
	return (
		<RouterLink
			data-comp="work-banner"
			to={to}
			css={{
				display: 'block',
			}}
		>
			<Container coastToCoastSmall={true}>
				<div
					css={{
						zIndex: 0,
						overflow: 'hidden',
					}}
				>
					<div
						css={{
							transformOrigin: 'center center',
							transition: 'transform 500ms ease-in-out',
							'[data-comp="work-banner"]:hover &': {
								transform: 'scale(1.025)',
							},
						}}
					>
						<Img fluid={fluidImageData} imgStyle={{ display: 'block' }} />
					</div>
				</div>
			</Container>
			<Container>
				<Row
					style={{
						position: 'relative',
						zIndex: 1,
						marginTop: '-1.5rem',
						[media.mediumUp]: {
							marginTop: '-3.25rem',
						},
					}}
				>
					<Cell
						cols={{
							[media.small]: 12,
							[media.mediumUp]: 8,
						}}
						offset={{
							[media.small]: 0,
							[media.mediumUp]: 1,
						}}
						style={{
							'& > * + *': {
								marginTop: '0.25rem',
							},
							[media.mediumUp]: {
								'& > * + *': {
									marginTop: '1rem',
								},
							},
						}}
					>
						{title && (
							<div
								css={[
									{
										display: 'inline-block',
										...typeRamp.light_30,

										[media.mediumUp]: {
											...typeRamp.light_80,
										},
									},
									buildStandaloneLinkStyle({
										hoverSelector: '[data-comp="work-banner"]:hover &',
									}),
								]}
							>
								{title}
							</div>
						)}
						{description && (
							<div
								css={{
									...typeRamp.light_16,
								}}
							>
								<span>{description}</span>
							</div>
						)}
					</Cell>
				</Row>
			</Container>
		</RouterLink>
	)
}

WorkBannerLink.propTypes = propTypes
WorkBannerLink.defaultProps = defaultProps

export default WorkBannerLink
