/** @jsx jsx */
import { useStaticQuery, graphql } from 'gatsby'
import { jsx } from '@emotion/core'

import Helmet from '../components/HelmetHelper'
import { InlineLink } from '../components/content/Link'
import Container from '../components/Container'
import Text from '../components/content/Text'
import {
	THEME_CITRON,
	media,
} from '../constants'
import Slide, {
	SLIDE_LAYOUT_FULL_SCREEN,
} from '../components/Slide'
import WorkBanner from '../components/content/WorkBannerLink'
import { LayoutIndented } from '../components/content/Layout'
import { provideAccentTheme } from '../context/AccentThemeContext'
import { CopyButtonInline } from '../components/CopyButton'
import HomeHighlightReel from '../components/HomeHighlightReel'
import { useMemo } from 'react'

const workBanners = [
	{
		id: 'facebook',
		title: 'Facebook',
		path: '/work/facebook',
		description: 'Expression & identity for 3 billion users.',
	},
	{
		id: 'google',
		title: 'Google',
		path: '/work/google',
		description: 'Ten years of product evolution.',
	},
	{
		id: 'coinbase',
		title: 'Coinbase',
		path: '/work/coinbase',
		description: 'Building a bold new frontier for crypto.',
	},
	{
		id: 'credit-karma',
		title: 'Credit Karma',
		path: '/work/credit-karma',
		description: 'Charting a path to product innovation.',
	},
	{
		id: 'toyota-research-institute',
		title: 'Toyota Research Institute',
		path: '/work/toyota-research-institute',
		description: 'Mobility with soul, AI at heart.',
	},
]

const IndexPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			graphics: allFile(
				filter: { relativeDirectory: { eq: "home" } }
				sort: { fields: name, order: ASC }
			) {
				nodes {
					name
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		}
	`)

	const graphicsByName = useMemo(() => {
		return data.graphics.nodes.reduce((result, node) => {
			return {
				...result,
				[node.name]: node,
			}
		}, {})
	}, [data])

	return (
		<div>
			<Helmet>
				<title>Home</title>
				<meta content="Free Association" property="og:title" />
				<meta content="Free Association" name="twitter:title" />
				<meta
					name="description"
					content="Free Association is a design agency focused on digital products and brand systems. We’ve built longstanding partnerships with companies like Google, Toyota, eBay, PayPal, Waze, Equinox and a range of new ventures."
				/>
				<meta
					property="og:description"
					content="Free Association is a design agency focused on digital products and brand systems. We’ve built longstanding partnerships with companies like Google, Toyota, eBay, PayPal, Waze, Equinox and a range of new ventures."
				/>
			</Helmet>
			<Slide
				layout={SLIDE_LAYOUT_FULL_SCREEN}
				style={{
					[media.small]: {
						paddingBottom: '0rem',
						paddingTop: '5rem',
					},
					[media.large]: {
						paddingTop: '2rem',
						paddingBottom: '2rem',
					},
				}}
			>
				<HomeHighlightReel />
			</Slide>
			<Slide
				style={{
					[media.small]: {
						paddingTop: '2rem',
						minHeight: 0,
					},
				}}
			>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								Free Association is a design agency focused on digital products
								and brand systems. We’ve built longstanding partnerships with
								companies like Google, Facebook, Toyota, eBay, PayPal, Waze and
								a range of new ventures.
							</p>
							<p className="--small">
								<InlineLink to="/work">Our Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			{workBanners.map((workItem, idx) => {
				return (
					<Slide
						key={workItem.id}
						trackingKey={workItem.id}
						style={{
							[media.small]: {
								// Treat this group of slides sort of as it's own slide on small
								paddingTop: idx === 0 ? 0 : '4rem',
								paddingBottom: idx === workBanners.length - 1 ? 0 : '4rem',
							},
						}}
					>
						<WorkBanner
							to={workItem.path}
							title={workItem.title}
							description={workItem.description}
							fluidImageData={graphicsByName[workItem.id].childImageSharp.fluid}
						/>
					</Slide>
				)
			})}
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--headline">Let’s work together.</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(IndexPage, THEME_CITRON)
