/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react'
import { media, typeRamp } from '../constants'
import Container from './Container'
import { Row, Cell } from './Grid'

const HomeHighlightReel = () => {
	const bgVideoRef = useRef(null)

	return (
		<div
			css={{
				transform: 'translateY(20px)',

				[media.medium]: {
					transform: 'translateY(37px)',
				},
				[media.large]: {
					transform: 'translateY(51px)',
				},
			}}
		>
			<Container coastToCoastSmall={true}>
				<Row>
					<Cell cols={12}>
						<div
							css={{
								position: 'relative',
							}}
						>
							<svg
								css={{
									width: '100%',
									display: 'block',
								}}
								viewBox="0 0 16 9"
							/>

							<video
								ref={bgVideoRef}
								src="/media/fa-highlight-reel-bg.mp4"
								autoPlay={true}
								loop
								playsInline={true}
								muted
								css={{
									top: 0,
									left: 0,
									position: 'absolute',
									width: '100%',
									// border: '1px solid blue',
								}}
							/>
						</div>
					</Cell>
				</Row>
			</Container>
			<Container>
				<Row>
					<Cell cols={12}>
						<h1
							css={{
								transform: 'translateY(-20px)',
								...typeRamp.light_40,
								[media.medium]: {
									...typeRamp.light_80,
									transform: 'translateY(-37px)',
								},
								[media.large]: {
									...typeRamp.light_96,
									transform: 'translateY(-51px)',
								},
								'@media (min-width: 1280px)': {
									transform: 'translateY(-51px) translateX(-57px)',
								},
							}}
						>
							The future is free.
						</h1>
					</Cell>
				</Row>
			</Container>
		</div>
	)
}

export default HomeHighlightReel
